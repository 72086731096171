.publication{
    border: 2px solid black;
    padding: .8rem;
    margin-bottom: 1rem;
    cursor: pointer;
}
.publication-btn{
    background-color: transparent !important;
}
.publication-img{
    max-width: 100%;
}
.full-publication-img{
    width: 50%;
    margin-bottom: 2rem;
}
.form-control.publications-search{
    margin-bottom: 4rem;
    background-color: white !important;
    border: 2px solid black !important;
    color: black !important;
    font-family: "e-Ukraine-Bold";
}
.pdf-document{
    min-height: 500px;
}
.pdf-page{
    width: fit-content;
    border: 3px solid black;
}
.journal-link{
    text-decoration: underline;
    color: black;
}
.abstract{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}