$inputBtnHeight: calc(2.1em + .75rem + 2px);

@mixin input {
    border-radius: 0 !important;
    color: black !important;
    border: 2px solid black !important;
    height: $inputBtnHeight !important;
    &:focus{
        box-shadow: none !important;
    }
    &:disabled{
        color: rgb(97, 97, 97) !important;
    }
}
@mixin label{
    font-family: 'e-Ukraine-Bold';
    font-size: 1.3rem;
}
@mixin mainBtn{
    border-radius: 0 !important;
    background-color: black !important;
    color: white !important;
    
    min-height: $inputBtnHeight !important;
    transition: none !important;
    border: 0 !important;
    &:focus{
        box-shadow: none !important;
    }
}
@mixin outlineBtn{
    @include mainBtn;
    background: none !important;
    border: 1px solid black !important;
    color: black !important;
}
@mixin modal{
    border-radius: 0 !important;
    font-family: e-Ukraine-Medium !important;
    .modal-header{
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}
@mixin validFormControl{
    background-image: url(./icons/tick.svg) !important;
}
@mixin arrowUp{
    margin-top: 10px;
    margin-bottom: -10px;
    align-self: center;
    position: relative;
    border: solid white;
    border-width: 2px 0 0 2px;
    display: inline-block;
    width: 32px;
    height: 32px;
    transform: rotate(45deg);
}

.pagination{
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
    gap: 2rem;

    li a {
        padding: .2rem 1rem;
        border: gray 1px solid;
        cursor: pointer;
    }
    li.previous a,
    li.next a,
    li.break a {
        border-color: black;
        color: black;
        text-decoration: none;
    }
    li.selected a {
        background-color: black;
        border-color: transparent;
        color: white;
        min-width: 32px;
    }
    li.disabled a {
        color: grey;
    }
    li.disable,
    li.disabled a {
        cursor: default;
    }
}